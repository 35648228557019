// subscribeOnlineStatus.js
'use strict';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

/**
 * Subscribe online status
 * @kind action
 * @param {string} {userId} - user id.
 * @param {bool} {shouldRetry} - should retry to subscribe channel.
 * @return {Promise} Action promise.
 */
const subscribeOnlineStatus =
  ({ userId, shouldRetry }) =>
  async dispatch => {
    if (!userId) {
      return dispatch({ type: '' });
    }

    if (
      userId === undefined ||
      userId === 'undefined' ||
      userId.length !== 24
    ) {
      return dispatch({ type: '' });
    }

    const messageEvent = {
      type: MessageTypes.USER_ONLINE_STATUS,
      payload: { userId, shouldRetry },
    };
    sendMessageToSW(messageEvent);
  };

export default subscribeOnlineStatus;
