// UserAvatar.js
import { connect } from 'react-redux';
import UserAvatar from '../component/UserAvatar.jsx';
import getUserData from '../selector/getUserData.js';
import getMeData from '../selector/getMeData.js';

const mapStateToProps = (state, { id }) => {
  const meId = getMeData(state, 'id');
  const isMe = id === meId;
  const username = id
    ? isMe
      ? getMeData(state, 'username')
      : getUserData(state, id, 'username')
    : null;
  return {
    meId,
    username,
  };
};

export default connect(mapStateToProps)(UserAvatar);
