// addInViewportUserId.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';

/**
 * Add in viewport user id
 * @kind action
 * @param {string} {id} - user id
 * @return {Promise} Action promise.
 */
const addInViewportUserId =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    if (!id) return;

    const inViewportUserIds =
      getOperationData(getState(), ['pusher'], 'inViewportUserIds') || [];

    if (inViewportUserIds.includes(id)) return;

    inViewportUserIds.push(id);

    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['pusher', 'inViewportUserIds'],
        data: inViewportUserIds,
      },
    });
  };

export default addInViewportUserId;
