// removeInViewportUserId.js
'use strict';
import { SET_OPERATION_DATA } from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';

/**
 * Remove in viewport user id
 * @kind action
 * @param {string} {id} - user id
 * @return {Promise} Action promise.
 */
const removeInViewportUserId =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    if (!id) return;

    const inViewportUserIds =
      getOperationData(getState(), ['pusher'], 'inViewportUserIds') || [];

    if (!inViewportUserIds.includes(id)) return;

    return dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['pusher', 'inViewportUserIds'],
        data: inViewportUserIds.filter(inViewportId => inViewportId !== id),
      },
    });
  };

export default removeInViewportUserId;
