// CanvasCircle.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../style/variables.js';

export class CanvasCircle extends React.PureComponent {
  constructor(props) {
    super(props);

    this.canvas = null;
    this.ctx = null;
  }

  state = {
    diameter: 0,
  };

  setDiameter = () => {
    if (this.container) {
      const { circleOffset } = this.props;
      this.setState({ diameter: this.container.clientWidth + circleOffset });
    }
  };

  setResizeObserver = () => {
    if (this.container) {
      this.resizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (entry.target === this.container) {
            this.setDiameter();
          }
        });
      });
      this.resizeObserver.observe(this.container);
    }
  };

  componentDidMount() {
    this.setDiameter();
    this.setResizeObserver();
  }

  componentDidUpdate(prevProps, prevState) {
    const { ctx } = this;
    const { circleLineWidth, circleOffset, colorOne, colorTwo } = this.props;
    const { diameter } = this.state;

    if (
      circleOffset !== prevProps.circleOffset ||
      circleLineWidth !== prevProps.circleLineWidth ||
      diameter !== prevState.diameter
    ) {
      this.setDiameter();
    }

    if (
      diameter !== prevState.diameter ||
      circleLineWidth !== prevProps.circleLineWidth ||
      colorTwo !== prevProps.colorTwo ||
      colorOne !== prevProps.colorOne
    ) {
      if (ctx) {
        ctx.beginPath();

        const center = diameter + circleLineWidth * 4;
        const gradient = ctx.createLinearGradient(0, 0, 0, center * 2);

        gradient.addColorStop(0, colorOne || color.tealBlue);
        gradient.addColorStop(1.0, colorTwo || color.tealBlue);

        ctx.strokeStyle = gradient;

        ctx.arc(center, center, diameter + circleLineWidth * 3, 0, 2 * Math.PI);
        ctx.lineWidth = circleLineWidth * 2;
        ctx.stroke();
        ctx.closePath();
      }
    }
  }

  componentWillUnmount() {
    this.resizeObserver?.disconnect();
  }

  render() {
    const { circleLineWidth, circleOffset } = this.props;
    const { diameter } = this.state;

    const center = diameter + circleLineWidth * 4;

    return (
      <StyledCanvasCircle
        ref={container => {
          if (container) {
            this.container = container;
          }
        }}
      >
        {diameter !== 0 && (
          <CanvasWrapper
            style={{
              position: 'absolute',
              top: `${-circleLineWidth * 2 - circleOffset / 2}px`,
              left: `${-circleLineWidth * 2 - circleOffset / 2}px`,
            }}
          >
            <Canvas
              key={name}
              ref={canvas => {
                if (canvas) {
                  this.canvas = canvas;
                  this.ctx = canvas.getContext('2d');
                }
              }}
              height={center * 2}
              width={center * 2}
              style={{
                width: `${center}px`,
                height: `${center}px`,
              }}
            />
          </CanvasWrapper>
        )}
      </StyledCanvasCircle>
    );
  }
}

CanvasCircle.propTypes = {
  circleLineWidth: PropTypes.number,
  circleOffset: PropTypes.number,
  colorOne: PropTypes.string,
  colorTwo: PropTypes.string,
};

CanvasCircle.defaultProps = {
  circleOffset: 4,
  circleLineWidth: 2,
};

const StyledCanvasCircle = styled.div``;
const CanvasWrapper = styled.div``;
const Canvas = styled.canvas``;

export default CanvasCircle;
