// UserStatusIndicator.js
import { connect } from 'react-redux';

import UserStatusIndicator from '../component/UserStatusIndicator.jsx';
import withRouter from '../component/WithRouter.jsx';

import getUserData from '../selector/getUserData.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';
import getOperationData from '../selector/getOperationData.js';

import subscribeOnlineStatus from '../action/subscribeOnlineStatus.js';
import addInViewportUserId from '../action/addInViewportUserId.js';
import removeInViewportUserId from '../action/removeInViewportUserId.js';

const mapStateToProps = (state, { userId }) => {
  const isOnline = userId ? getUserData(state, userId, 'isOnline') : false;
  return {
    isOnline,
    isLiveStreaming: getLiveStreamData(state, [userId], 'isLiveStreaming'),
    isPrivateLiveStreamStarted: getLiveStreamData(
      state,
      [userId],
      'isPrivateLiveStreamStarted'
    ),
    privateUserChannel: getOperationData(
      state,
      ['pusher', 'channels'],
      `private-enc-user@${userId}`
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    subscribeOnlineStatus: ({ userId }) =>
      dispatch(subscribeOnlineStatus({ userId })),
    addInViewportUserId: ({ id }) => dispatch(addInViewportUserId({ id })),
    removeInViewportUserId: ({ id }) =>
      dispatch(removeInViewportUserId({ id })),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserStatusIndicator)
);
