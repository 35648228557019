// homeUtils.js
'use strict';
import {
  BROWSE_FOLLOWING_CATEGORIES,
  BROWSE_EXPLORE_CATEGORIES,
  HOME_FEATURE_CATEGORIES,
  HOME_VIDEO_CATEGORIES,
} from '../RemoteConfigKeys.js';

export const HOME_PAGE_CONFIG = {
  video: {
    paths: ['/video'],
    categoriesRemoteConfigKey: HOME_VIDEO_CATEGORIES,
    isCategoryWidthLimited: false,
    shouldUseRetainedEvents: true,
  },
  home: {
    paths: ['/'],
    categoriesRemoteConfigKey: BROWSE_EXPLORE_CATEGORIES,
    tabsComponent: 'BrowseTab',
    isCategoryWidthLimited: false,
    shouldUseRetainedEvents: true,
  },
  following: {
    paths: ['/following'],
    categoriesRemoteConfigKey: BROWSE_FOLLOWING_CATEGORIES,
    tabsComponent: 'BrowseTab',
    isCategoryWidthLimited: true,
    shouldUseRetainedEvents: true,
  },
  livestream: {
    paths: ['/livestream'],
    categoriesRemoteConfigKey: HOME_FEATURE_CATEGORIES,
    isCategoryWidthLimited: false,
    shouldUseRetainedEvents: true,
  },
};

/**
 * Returns the configuration for the current home page based on the pathname.
 *
 * @param {object} [options] - An object containing the pathname.
 * @param {string} [options.pathname] - The pathname of the current page.
 * @returns {object} - The configuration for the current home page.
 */
export const getHomePageConfig = ({ pathname } = {}) => {
  const key = Object.keys(HOME_PAGE_CONFIG).find(key =>
    HOME_PAGE_CONFIG[key].paths.includes(pathname)
  );
  return key ? HOME_PAGE_CONFIG[key] : {};
};
