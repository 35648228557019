// UserAvatarWithStatus.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { DotSize } from '../style/variables.js';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import CanvasCircle from '../component/CanvasCircle.jsx';
import UserStatusIndicator from '../container/UserStatusIndicator.js';
import UserAvatar from '../container/UserAvatar.js';
import MeAvatar from '../container/MeAvatar.js';
import LongPress from '../component/LongPress.jsx';
import { LIVESTREAM_TYPE_LIVE_BADGE } from '../resource/userStatusIndicatorConstants.js';
import { ButtonId } from '../resource/mixpanel.js';
import { LIVESTREAM_PATH } from '../resource/liveStreamConstants.js';
import { ProfileListType } from '../resource/profileConstants.js';

import LoadingImage from '../../img/loading.png';
import ProfileIcon from '../../img/placeholder-avatar-cube-60.svg';

/**
 * Determines the appropriate dot size based on the given avatar size value.
 *
 * @param {Object} [options] - The options object.
 * @param {number} [options.size] - The avatar size value used to determine the dot size.
 * @returns {DotSize} The corresponding dot size based on the input size value.
 * @typedef {number} DotSize
 * @property {number} SIZE_8 - Dot size of 8.
 * @property {number} SIZE_12 - Dot size of 12.
 * @property {number} SIZE_16 - Dot size of 16.
 * @property {number} SIZE_20 - Dot size of 20.
 * @property {number} SIZE_26 - Dot size of 26.
 * @property {number} SIZE_28 - Dot size of 28.
 * @property {number} SIZE_32 - Dot size of 32.
 */
export const getDotSize = ({ size } = {}) => {
  if (size >= 110) return DotSize.SIZE_32;
  if (size >= 100) return DotSize.SIZE_28;
  if (size >= 88) return DotSize.SIZE_26;
  if (size >= 64) return DotSize.SIZE_20;
  if (size >= 48) return DotSize.SIZE_16;
  if (size >= 28) return DotSize.SIZE_12;
  return DotSize.SIZE_8;
};

/**
 * Calculates the corresponding border size based on the given dot size.
 *
 * @param {Object} [options] - The options object containing dot size.
 * @param {DotSize} [options.dotSize] - The dot size used to calculate the border size.
 * @returns {number} The corresponding border size.
 */
export const calculateBorderSize = ({ dotSize } = {}) => {
  switch (dotSize) {
    case DotSize.SIZE_8:
      return 1;
    case DotSize.SIZE_12:
      return 1.5;
    case DotSize.SIZE_16:
      return 2;
    case DotSize.SIZE_20:
      return 2.5;
    case DotSize.SIZE_26:
      return 3;
    case DotSize.SIZE_28:
      return 3.5;
    case DotSize.SIZE_32:
      return 4;
    case DotSize.SIZE_0:
    default:
      return 0;
  }
};

export const UserAvatarWithStatus = ({
  meId = null,
  id = '',
  username = '',
  latestMessageId = '',
  latestMessageType = 'flix',
  size,
  circleOffset = 4,
  circleLineWidth = 2,
  imageStyle,
  srcSet,
  liveStreamType = LIVESTREAM_TYPE_LIVE_BADGE,
  livestreamSize = 18,
  shouldShowLiveStream = false,
  shouldShowOnline = false,
  isLiveStreaming = false,
  isOnline = false,
  pathname = 'flix',
  disableLink = false,
  setProfileSelectedImage = () => null,
  fullSizeAvatarImageSource = '',
  openModal = () => null,
  shouldUseModal = false,
  ...restProps
}) => {
  const { search } = useLocation();
  if (!id) {
    return <UserAvatar size={size} imageStyle={imageStyle} />;
  }
  const hasStory = latestMessageType === 'story' && !!latestMessageId;

  const elementId =
    restProps?.['data-element_id'] || ButtonId.All.ButtonCardAvatar;
  const trackingPayload = {
    ...(restProps?.['data-tracking_payload'] || {}),
    'user.id': id,
    'user.username': username,
  };
  const dotSize = getDotSize({ size });
  const borderSize = calculateBorderSize({ dotSize });
  const hasHalo = isLiveStreaming || hasStory;

  const renderAvatarWithStatus = () => (
    <StyledUserAvatarWithStatus
      data-element_id={elementId}
      data-tracking_payload={trackingPayload}
      size={size}
    >
      {isLiveStreaming ? (
        <CanvasCircle
          colorOne="#a445b3"
          colorTwo="#ff0066"
          circleOffset={circleOffset}
          circleLineWidth={circleLineWidth}
        />
      ) : hasStory ? (
        <CanvasCircle
          circleOffset={circleOffset}
          circleLineWidth={circleLineWidth}
        />
      ) : null}
      {meId != null && meId === id ? (
        <MeAvatar size={size} />
      ) : (
        <UserAvatar
          id={id}
          size={size}
          imageStyle={imageStyle}
          srcSet={srcSet}
        />
      )}
      <UserStatusIndicatorWrapper
        circleOffset={isLiveStreaming || hasStory ? circleOffset : 0}
        isOnline={isOnline}
        isLiveStreaming={isLiveStreaming}
        size={size}
        hasHalo={hasHalo}
      >
        <UserStatusIndicator
          userId={id}
          liveStreamType={liveStreamType}
          livestreamSize={livestreamSize}
          dotSize={dotSize}
          borderSize={borderSize}
          shouldShowLiveStream={shouldShowLiveStream}
          shouldShowOnline={shouldShowOnline}
        />
      </UserStatusIndicatorWrapper>
    </StyledUserAvatarWithStatus>
  );

  if (disableLink) {
    return <>{renderAvatarWithStatus()}</>;
  }

  if (shouldUseModal) {
    const handleAvatarClickWithModal = () => {
      if (isLiveStreaming || hasStory) {
        openModal('UserAvatarModal');
      } else {
        setProfileSelectedImage({
          imageSrc: fullSizeAvatarImageSource,
          loadingSrc: LoadingImage,
          defaultSrc: ProfileIcon,
          isAvatar: true,
          userId: id,
        });
        openModal('ImageCover');
      }
    };

    return (
      <UserAvatarWithModalWrapper onClick={handleAvatarClickWithModal}>
        {renderAvatarWithStatus()}
      </UserAvatarWithModalWrapper>
    );
  }

  const toPath = isLiveStreaming
    ? `/user/${id}/${LIVESTREAM_PATH}`
    : hasStory
      ? {
          // Keep UserAvatar's link search equals to current page's link search
          // to ensure `state` (listPath) transmit to next story message page
          pathname: `/story/${latestMessageId}`,
          search,
        }
      : `/user/${id}${pathname ? `/${pathname}` : ''}`;
  const state =
    !isLiveStreaming && hasStory
      ? { listPath: ['outbox', ProfileListType.STORY, id] }
      : {};

  const handleLongPressUserAvatar = () => {
    setProfileSelectedImage({
      imageSrc: fullSizeAvatarImageSource,
      loadingSrc: LoadingImage,
      defaultSrc: ProfileIcon,
      isAvatar: true,
      userId: id,
    });
    openModal('ImageCover');
  };

  const haloStatus = isLiveStreaming ? 'livestream' : hasStory ? 'story' : '';

  return (
    <LongPress onLongPress={handleLongPressUserAvatar}>
      <Link
        to={toPath}
        state={state}
        data-element_id={elementId}
        data-tracking_payload={{
          ...trackingPayload,
          ...(haloStatus && {
            'halo.status': haloStatus,
          }),
        }}
      >
        {renderAvatarWithStatus()}
      </Link>
    </LongPress>
  );
};

UserAvatarWithStatus.propTypes = {
  meId: PropTypes.string,
  id: PropTypes.string,
  username: PropTypes.string,
  latestMessageId: PropTypes.string,
  latestMessageType: PropTypes.oneOf(['flix', 'story']),
  size: PropTypes.number.isRequired,
  circleOffset: PropTypes.number,
  circleLineWidth: PropTypes.number,
  imageStyle: PropTypes.object,
  srcSet: PropTypes.array,
  liveStreamType: PropTypes.string,
  livestreamSize: PropTypes.number,
  pathname: PropTypes.string,
  onlineType: PropTypes.string,
  shouldShowLiveStream: PropTypes.bool,
  shouldShowOnline: PropTypes.bool,
  isLiveStreaming: PropTypes.bool,
  disableLink: PropTypes.bool,
  isOnline: PropTypes.bool,
  setProfileSelectedImage: PropTypes.func,
  fullSizeAvatarImageSource: PropTypes.string,
  openModal: PropTypes.func,
  shouldUseModal: PropTypes.bool,
};

const StyledUserAvatarWithStatus = styled.div`
  position: relative;
  ${({ size }) => `
  width: ${size}px;
  height: ${size}px;
`}
`;

const UserAvatarWithModalWrapper = styled.div`
  cursor: pointer;
`;

const UserStatusIndicatorWrapper = styled.div`
  ${({ isLiveStreaming, isOnline, hasHalo, circleOffset = 0 }) => {
    if (isLiveStreaming) {
      return css`
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, calc(50% + ${circleOffset}px));
      `;
    } else if (isOnline) {
      return css`
        position: absolute;
        bottom: ${hasHalo ? '-6%' : '-2.788%'};
        right: ${hasHalo ? '-6%' : '-2.788%'};
      `;
    }
  }}
`;

export default UserAvatarWithStatus;
